import {
  Component,
  ElementRef, OnInit,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EntregaViewComponent } from '@dft/modules/tipificacao/entrega/entrega-view/entrega-view.component';
import { DialogService, FormComponent } from '@dft/shared/dialog/dialog.service';
import { StatusMapaUnidadeEnum } from '@dft/shared/enums/status-mapa-unidade.enum';
import { Entrega } from '@dft/shared/models/entrega';
import { MapaUnidade } from '@dft/shared/models/mapa-unidade';
import { MapaUnidadeProdutosService } from '@dft/shared/services/mapa-unidade-produtos.service';
import { MapaUnidadeService } from '@dft/shared/services/mapa-unidade.service';
import { getStringSemAcento, getStringSemPontos } from 'src/app/shared/util/string.util';

@Component({
  templateUrl: 'selecao-entregas-form.component.html',
  styleUrls: ['selecao-entregas-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelecaoEntregasFormComponent implements OnInit, FormComponent {

  @ViewChild(MatPaginator, { static: true }) paginador: MatPaginator;
  @ViewChild('filtraEntregas', { static: true }) filtroInput: ElementRef;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  form: FormGroup;
  formValue = null;
  readonly = false;

  datasource: MatTableDataSource<any>;
  displayedColumns = ['entrega', 'codigo'];
  resultsLength = 0;
  filtro = '';

  listaMapaUnidade: MapaUnidade[];
  mapaUnidadeProdutos: Entrega[];

  mapaSelecionado: MapaUnidade;

  constructor(
    private _formBuilder: FormBuilder,
    private _mapaUnidadeService: MapaUnidadeService,
    private _mapaUnidadeProdutosService: MapaUnidadeProdutosService,
    private dialogService: DialogService
  ) {
    this.form = this._formBuilder.group({
      entregas: new FormArray([]),
    });
  }

  ngOnInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginador.pageIndex = 0));
    this.obtemEntregas();
  }

  get entregas() {
    return this.form.get('entregas') as FormArray;
  }

  private obtemEntregas() {
    const idUnidade = this.formValue['idUnidade'];
    const entregasSelecionadas = this.formValue['entregas'] as Array<number>;

    this._mapaUnidadeProdutosService.buscarEntregasMapasUnidade(idUnidade).subscribe((entregas) => {
      const _listaDeEntregas = entregas.filter(
        (entrega) => !entregasSelecionadas.includes(entrega.id)
      );
      _listaDeEntregas.forEach((entrega) => {
        this.entregas.push(
          this._formBuilder.group({
            entrega: this._formBuilder.control(entrega),
            selecionado: this._formBuilder.control(false),
          })
        );
      });

      this._mapaUnidadeService.listarMapasUnidade().subscribe((mapasUnidades) => {
        this.listaMapaUnidade = mapasUnidades
          .filter(mu => mu.status === StatusMapaUnidadeEnum.FINALIZADO)
          .filter(mu => mu.idUnidade === idUnidade);
      });

      this.datasource = new MatTableDataSource(this.entregas.controls);
      this.resultsLength = _listaDeEntregas.length;
      this.datasource.paginator = this.paginador;

      this.configuraOrdenacao();

      // Configura filtro
      this.datasource.filterPredicate = (formGroup: FormGroup, filter: string) => {
        const searchTerms = JSON.parse(filter);
        const dadosEntrega: Entrega = formGroup.value.entrega;

        const filtroTexto =
          (getStringSemAcento(dadosEntrega.nome).includes(getStringSemAcento(searchTerms.texto)) ||
            getStringSemAcento(dadosEntrega.codigo).includes(getStringSemAcento(searchTerms.texto)) ||
            getStringSemAcento(dadosEntrega.atividade).includes(getStringSemAcento(searchTerms.texto)) ||
            getStringSemAcento(dadosEntrega.fluxoTrabalho).includes(getStringSemAcento(searchTerms.texto)) ||
            getStringSemAcento(dadosEntrega.categoriaServico.descricao).includes(getStringSemAcento(searchTerms.texto)));

        const filtroMapa = !!this.mapaUnidadeProdutos.find(p => p.id === dadosEntrega.id)?.nome;

        return searchTerms.mapa !== ''
          ? filtroTexto && filtroMapa
          : filtroTexto;
      };
    });
  }

  private configuraOrdenacao() {
    // Configura o sorting considerando o valor do control de entrega e ordena registros
    this.datasource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data.controls.entrega.value[sortHeaderId] === 'string') {
        return getStringSemAcento(data.controls.entrega.value[sortHeaderId]);
      }
      return data.controls.entrega.value[sortHeaderId];
    };
    this.datasource.sort = this.sort;
  }


  filtrarPorMapa(selecionado?: MapaUnidade) {
    this.mapaSelecionado = selecionado;

    if (this.mapaSelecionado) {
      this._mapaUnidadeProdutosService
        .buscarEntregasMapa(this.mapaSelecionado.id.toString())
        .subscribe((mapaUnidadeProdutos) => {
          this.mapaUnidadeProdutos = mapaUnidadeProdutos;
          this.filtrar();
        });
    } else {
      this.limparFiltro();
    }

  }

  filtrar() {
    const filterValues = { texto: '', mapa: '' };
    if (this.filtro) {
      filterValues.texto = getStringSemPontos(getStringSemAcento(this.filtro));
    }
    if (this.mapaSelecionado) {
      filterValues.mapa = getStringSemAcento(this.mapaSelecionado.nome);
    }
    this.datasource.filter = JSON.stringify(filterValues);
    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  limparFiltro() {
    this.filtro = '';
    this.filtrar();
  }
  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  exibeEntrega(entrega: Entrega): void {
    this.dialogService.prompt({
      formComponent: EntregaViewComponent,
      value: entrega,
      readOnly: true,
      title: 'VISUALIZAR DETALHES DA ENTREGA',
      close: 'FECHAR',
    });
  }

}
