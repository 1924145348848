import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MapaUnidade } from '../models/mapa-unidade';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class MapaUnidadeService {
  apiMapaUnidade = `${environment.urlApi}/mapas-unidades`;

  constructor(private http: HttpClient) { }

  public listarMapasUnidade() {
    return this.http.get<MapaUnidade[]>(`${this.apiMapaUnidade}`, httpOptions);
  }

  public buscarMapaUnidade(id: number) {
    return this.http.get<MapaUnidade>(
      `${this.apiMapaUnidade}/${id}`,
      httpOptions
    );
  }

  public inserirMapaUnidade(mapaUnidade: MapaUnidade) {
    return this.http.post<MapaUnidade>(
      `${this.apiMapaUnidade}`,
      mapaUnidade,
      httpOptions
    );
  }

  public deletarMapaUnidade(mapaUnidade: MapaUnidade) {
    return this.http.delete<MapaUnidade>(
      `${this.apiMapaUnidade}/${mapaUnidade.id}`,
      httpOptions
    );
  }

  public editarMapaUnidade(mapaUnidade: MapaUnidade) {
    return this.http.put<MapaUnidade>(
      `${this.apiMapaUnidade}/${mapaUnidade.id}`,
      mapaUnidade,
      httpOptions
    );
  }
}
