import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@dft/security/auth/auth.service';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { STATUS_ENTREGA_MAP, StatusEntregaEnum } from '@dft/shared/enums/status-entrega.enum';
import { TipoPerfilEnum } from '@dft/shared/enums/tipo-perfil.enum';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { CategoriaServicoService } from '@dft/shared/services/categoria-servico.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mapa-unidade-criar-produtos-form.component.html',
  styleUrls: ['./mapa-unidade-criar-produtos-form.component.scss'],
})
export class MapaUnidadeCriarProdutosFormComponent implements OnInit {
  form: FormGroup;
  categorias: CategoriaServico[];
  formValue = null;
  readonly = false;

  isGestorGeral$: Observable<boolean> = this.authService.perfilSelecionado$.pipe(map(perfil =>
    perfil && perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL
  ));

  StatusEntrega = STATUS_ENTREGA_MAP;
  StatusEntregaEnum = StatusEntregaEnum;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly categoriaService: CategoriaServicoService,
    private authService: AuthService
  ) {
    this.form = this.formBuilder.group({
      idConjunto: ['', Validators.required],
      atividade: ['', Validators.required],
      nome: ['', Validators.required],
      fluxoTrabalho: ['', Validators.required],
      status: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isGestorGeral$.subscribe(isGestorGeral => {
      this.form.controls.status.setValue(
        isGestorGeral
          ? this.StatusEntrega.find(s => s.id === StatusEntregaEnum.ATIVA)
          : this.StatusEntrega.find(s => s.id === StatusEntregaEnum.NAO_VALIDADA)
      );
    });

    this.categoriaService.listarCategorias().subscribe(
      (categorias) => {
        this.categorias = categorias.filter((c) => c.tipo === TipoMetodologiaEnum.TIPICA);
        this.form.controls.idConjunto.setValue(categorias.find(c => c.id === this.formValue.idCategoria).id);
      },
      (error) => {
        console.error('Erro ao carregar as categorias de serviço:', error);
      }
    );

    if (this.formValue) {
      this.form.patchValue(this.formValue);
      this.form.controls.status.setValue(this.StatusEntrega.find(s => s.id === this.formValue.status));
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
