import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Entrega } from '../models/entrega';
import { IndicadorProdutividade } from '../models/indicador-produtividade';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class MapaUnidadeProdutosService {
  private readonly apiUrl = `${environment.urlApi}/mapas-unidades`;
  private readonly apiUrlProdutos = `${environment.urlApi}/mapas-unidades-produtos`;
  private readonly apiUrlMarcos = `${environment.urlApi}/mapas-unidades-marcos`;

  constructor(private readonly http: HttpClient) { }

  public buscarEntregasMapasUnidade(idUnidade: string): Observable<Entrega[]> {
    return this.http.get<Entrega[]>(`${this.apiUrl}/unidades/${idUnidade}/entregas`);
  }

  public buscarEntregasMapa(idMapaUnidade: string): Observable<Entrega[]> {
    return this.http.get<Entrega[]>(`${this.apiUrl}/${idMapaUnidade}/entregas`);
  }

  public buscarProdutosMapa(idMapaUnidade: string): Observable<Entrega[]> {
    return this.http.get<Entrega[]>(`${this.apiUrl}/${idMapaUnidade}/produtos`);
  }

  public buscarIndicadoresMapa(idMapaUnidade: string): Observable<IndicadorProdutividade[]> {
    return this.http.get<IndicadorProdutividade[]>(`${this.apiUrl}/${idMapaUnidade}/indicadores`);
  }

  public buscarMarcosProdutividadeMapa(idMapaUnidade: string): Observable<IndicadorProdutividade[]> {
    return this.http.get<IndicadorProdutividade[]>(`${this.apiUrl}/${idMapaUnidade}/marcos-produtividades`);
  }

  public salvarEntregas(idMapaUnidade: string, isRascunho: boolean, idsEntregas: number[]) {
    return this.http.post(
      `${this.apiUrl}/${idMapaUnidade}/entregas`,
      { idsProdutos: idsEntregas, rascunho: isRascunho },
      httpOptions
    );
  }

  public salvarIndicadores(idMapaUnidade: string, isRascunho: boolean, idsIndicadores: number[]) {
    return this.http.post(
      `${this.apiUrl}/${idMapaUnidade}/indicadores`,
      { idsProdutos: idsIndicadores, rascunho: isRascunho },
      httpOptions
    );
  }

  public inserirProduto(produto: Entrega) {
    return this.http.post(this.apiUrlProdutos, produto, httpOptions);
  }

  public editarProduto(produto: Entrega) {
    return this.http.put(`${this.apiUrlProdutos}/${produto.id}`, produto, httpOptions);
  }

  public deletarProduto(id: number) {
    return this.http.delete(`${this.apiUrlProdutos}/${id}`);
  }

  public inserirMarco(marco: IndicadorProdutividade) {
    return this.http.post(this.apiUrlMarcos, marco, httpOptions);
  }

  public editarMarco(marco: IndicadorProdutividade) {
    return this.http.put(`${this.apiUrlMarcos}/${marco.id}`, marco, httpOptions);
  }

  public deletarMarco(id: number) {
    return this.http.delete(`${this.apiUrlMarcos}/${id}`);
  }
}
